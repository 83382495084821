<template>
  <!--begin::Nav-->
  <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
    <div class="navi-item mb-2">
      <router-link :to="{ name: 'admin' }" class="navi-link py-4" active-class="active">
        <span class="navi-text font-size-lg">Пользователи</span>
      </router-link>
    </div>
    <div class="navi-item mb-2">
      <router-link :to="{ name: 'adminGroups' }" class="navi-link py-4" active-class="active">
        <span class="navi-text font-size-lg">Группы</span>
      </router-link>
    </div>
    <div class="navi-item mb-2">
      <a href="#" @click.prevent="logout" class="navi-link py-4">
        <span class="navi-icon mr-2">
          <span class="svg-icon"
            ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\legacy\metronic\theme\html\demo1\dist/../src/media/svg/icons\Navigation\Sign-out.svg-->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <path
                  d="M14.0069431,7.00607258 C13.4546584,7.00607258 13.0069431,6.55855153 13.0069431,6.00650634 C13.0069431,5.45446114 13.4546584,5.00694009 14.0069431,5.00694009 L15.0069431,5.00694009 C17.2160821,5.00694009 19.0069431,6.7970243 19.0069431,9.00520507 L19.0069431,15.001735 C19.0069431,17.2099158 17.2160821,19 15.0069431,19 L3.00694311,19 C0.797804106,19 -0.993056895,17.2099158 -0.993056895,15.001735 L-0.993056895,8.99826498 C-0.993056895,6.7900842 0.797804106,5 3.00694311,5 L4.00694793,5 C4.55923268,5 5.00694793,5.44752105 5.00694793,5.99956624 C5.00694793,6.55161144 4.55923268,6.99913249 4.00694793,6.99913249 L3.00694311,6.99913249 C1.90237361,6.99913249 1.00694311,7.89417459 1.00694311,8.99826498 L1.00694311,15.001735 C1.00694311,16.1058254 1.90237361,17.0008675 3.00694311,17.0008675 L15.0069431,17.0008675 C16.1115126,17.0008675 17.0069431,16.1058254 17.0069431,15.001735 L17.0069431,9.00520507 C17.0069431,7.90111468 16.1115126,7.00607258 15.0069431,7.00607258 L14.0069431,7.00607258 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                  opacity="0.3"
                  transform="translate(9.006943, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-9.006943, -12.000000) "
                />
                <rect
                  fill="#000000"
                  opacity="0.3"
                  transform="translate(14.000000, 12.000000) rotate(-270.000000) translate(-14.000000, -12.000000) "
                  x="13"
                  y="6"
                  width="2"
                  height="12"
                  rx="1"
                />
                <path
                  d="M21.7928932,9.79289322 C22.1834175,9.40236893 22.8165825,9.40236893 23.2071068,9.79289322 C23.5976311,10.1834175 23.5976311,10.8165825 23.2071068,11.2071068 L20.2071068,14.2071068 C19.8165825,14.5976311 19.1834175,14.5976311 18.7928932,14.2071068 L15.7928932,11.2071068 C15.4023689,10.8165825 15.4023689,10.1834175 15.7928932,9.79289322 C16.1834175,9.40236893 16.8165825,9.40236893 17.2071068,9.79289322 L19.5,12.0857864 L21.7928932,9.79289322 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                  transform="translate(19.500000, 12.000000) rotate(-90.000000) translate(-19.500000, -12.000000) "
                />
              </g></svg
            ><!--end::Svg Icon--></span
          >
        </span>
        <span class="navi-text">Выход</span>
      </a>
    </div>
  </div>
  <!--end::Nav-->
</template>

<script>
import { auth } from "@/core/services/auth";

export default {
  setup() {
    const logout = () => {
      auth.logOut();
    };

    return {
      logout,
    };
  },
};
</script>
