<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Profile Personal Information-->
        <div class="d-flex flex-row">
          <!--begin::Aside-->
          <div class="flex-row-auto offcanvas-mobile w-250px w-xxl-350px" id="kt_profile_aside">
            <!--begin::Profile Card-->
            <div class="card card-custom card-stretch">
              <!--begin::Body-->
              <div class="card-body pt-4">
                <SideNav />
              </div>
              <!--end::Body-->
            </div>
            <!--end::Profile Card-->
          </div>
          <!--end::Aside-->
          <!--begin::Content-->
          <div class="flex-row-fluid ml-lg-8">
            <!--begin::Card-->
            <router-view></router-view>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Profile Personal Information-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
export default {
  components: {
    SideNav,
  },
  setup() {},
};
</script>
